@charset "UTF-8";
/*
 * Variables
*/
/*
 * Mixins
*/
/*
 * Globals
*/
body {
  background-color: white;
  color: #2D2F32; }

@media (prefers-color-scheme: dark) {
  body {
    background-color: #2D2F32;
    color: #f0f8ff; } }

.show {
  display: block; }

.hide {
  display: none; }

.form-group--slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }

.form-group label {
  margin-right: auto;
  padding: .25rem .5rem;
  min-width: 1.5rem;
  border-radius: 5px;
  background-color: rgba(240, 248, 255, 0.8); }

.form-group [type='range'] {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1.5rem;
  background-color: transparent; }
  .form-group [type='range'], .form-group [type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; }
  .form-group [type='range']::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 13rem;
    height: 0.25rem;
    background: rgba(204, 204, 204, 0.8); }
  .form-group [type='range']::-moz-range-track {
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 13rem;
    height: 0.25rem;
    background: rgba(204, 204, 204, 0.8); }
  .form-group [type='range']::-ms-track {
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 13rem;
    height: 0.25rem;
    background: rgba(204, 204, 204, 0.8); }
  .form-group [type='range']::-webkit-slider-thumb {
    margin-top: -0.625rem;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 0.5rem;
    height: 1.5rem;
    background: #ccc; }
  .form-group [type='range']::-moz-range-thumb {
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 0.5rem;
    height: 1.5rem;
    background: #ccc; }
  .form-group [type='range']::-ms-thumb {
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    width: 0.5rem;
    height: 1.5rem;
    background: #ccc; }
  .form-group [type='range']::-ms-tooltip {
    display: none; }
  .form-group [type='range'] ~ output {
    display: none;
    position: absolute;
    top: 0;
    padding: .25rem .5rem;
    min-width: 1.5rem;
    border-radius: 5px;
    color: #f0f8ff;
    background: #7FB3D5; }
  .form-group [type='range']:active, .form-group [type='range']:focus-within, .form-group [type='range']:focus {
    outline: none; }
    .form-group [type='range']:active::-webkit-slider-thumb, .form-group [type='range']:focus-within::-webkit-slider-thumb, .form-group [type='range']:focus::-webkit-slider-thumb {
      background: #7FB3D5; }
    .form-group [type='range']:active::-moz-range-thumb, .form-group [type='range']:focus-within::-moz-range-thumb, .form-group [type='range']:focus::-moz-range-thumb {
      background: #7FB3D5; }
    .form-group [type='range']:active::-ms-thumb, .form-group [type='range']:focus-within::-ms-thumb, .form-group [type='range']:focus::-ms-thumb {
      background: #7FB3D5; }
    .form-group [type='range']:active ~ output, .form-group [type='range']:focus-within ~ output, .form-group [type='range']:focus ~ output {
      display: block; }

.Nipple {
  position: relative; }
  .Nipple > div {
    max-width: calc( 100vw - 90px);
    border-radius: 5px; }
  .Nipple .Nipple-debug {
    position: absolute;
    bottom: 15px;
    left: 15px;
    text-align: left;
    color: #7FB3D5;
    pointer-events: none; }
    .Nipple .Nipple-debug > ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }

.App {
  text-align: center;
  user-select: none; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40px;
  margin: 1rem 0;
  position: relative; }

.App-header {
  background-color: rgba(240, 248, 255, 0.8);
  color: #2D2F32;
  position: relative;
  z-index: 5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  .App-header.video--active {
    height: 75vw;
    max-height: 320px; }
  .App-header .App-headerTitle {
    padding: 2rem; }
  .App-header .App-videoContainer {
    display: none; }
    .App-header .App-videoContainer.connected {
      display: block; }
    .App-header .App-videoContainer.connected.hide, .App-header .App-videoContainer.disconnected.hide {
      display: none; }

.App-videoContainer {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding-bottom: 20px;
  min-height: 240px;
  height: 320px;
  max-height: 75vw;
  overflow: hidden;
  resize: vertical;
  user-select: initial; }
  .App-videoContainer::after {
    content: "☟";
    pointer-events: none;
    font-size: 14px;
    position: absolute;
    height: 20px;
    width: 20px;
    text-align: center;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: #f0f8ff;
    color: #2D2F32; }
  .App-videoContainer .App-videoFeed {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: block;
    transform: rotate(180deg);
    position: relative;
    z-index: 5; }
    .App-videoContainer .App-videoFeed.test--active {
      transform: rotate(0); }

@media screen and (min-width: 680px) {
  .App-videoContainer {
    height: 320px; }
    .App-videoContainer .App-videoFeed {
      width: auto;
      margin-left: auto; } }

.App-statusBar {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  padding: .5rem;
  background-color: rgba(45, 47, 50, 0.2);
  border-bottom-right-radius: 5px;
  transition: background .2s ease; }
  .App-statusBar:focus-within {
    background-color: rgba(45, 47, 50, 0.5); }
  .App-statusBarToggles {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .App-statusBarToggles > div + div {
      margin-left: .25rem; }
    .App-statusBarToggles > div {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      cursor: pointer; }
      .App-statusBarToggles > div span {
        height: 1rem;
        padding: 0.2rem 0.25rem;
        border-radius: 5px;
        line-height: 1.5;
        color: white;
        font-weight: 700;
        transition: all .2s ease; }
      .App-statusBarToggles > div.enabled span {
        background-color: rgba(116, 224, 139, 0.5); }
        .App-statusBarToggles > div.enabled span:active, .App-statusBarToggles > div.enabled span:hover, .App-statusBarToggles > div.enabled span:focus {
          background-color: rgba(116, 224, 139, 0.9); }
      .App-statusBarToggles > div.disabled span {
        background-color: rgba(245, 95, 118, 0.5); }
        .App-statusBarToggles > div.disabled span:active, .App-statusBarToggles > div.disabled span:hover, .App-statusBarToggles > div.disabled span:focus {
          background-color: rgba(245, 95, 118, 0.9); }
      .App-statusBarToggles > div.open span {
        background-color: rgba(240, 248, 255, 0.5);
        color: rgba(45, 47, 50, 0.5); }
        .App-statusBarToggles > div.open span:active, .App-statusBarToggles > div.open span:hover, .App-statusBarToggles > div.open span:focus {
          background-color: rgba(240, 248, 255, 0.8); }
      .App-statusBarToggles > div.closed span {
        background-color: rgba(45, 47, 50, 0.5);
        color: rgba(240, 248, 255, 0.5); }
        .App-statusBarToggles > div.closed span:active, .App-statusBarToggles > div.closed span:hover, .App-statusBarToggles > div.closed span:focus {
          background-color: rgba(45, 47, 50, 0.8); }
  .App-statusBarOptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: auto;
    overflow: hidden;
    height: 100%; }
    .App-statusBarOptions > div {
      overflow: hidden;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      max-height: 60px;
      transition: max-height .2s ease; }
      .App-statusBarOptions > div .App-input {
        margin-left: 0;
        max-width: 7.25rem; }
      .App-statusBarOptions > div .App-btn {
        margin-right: 0; }
    .App-statusBarOptions .open {
      padding-top: .5rem; }
    .App-statusBarOptions .closed {
      padding-top: 0;
      max-height: 0px;
      transition: all .2s ease; }

.App-toggleBar {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -35px;
  left: 0;
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.5);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }
  .App-toggleBar .App-toggleBarBtn {
    border: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: .25rem;
    margin-left: 0;
    background-color: rgba(240, 248, 255, 0.8);
    color: rgba(127, 179, 213, 0.8);
    font-weight: bold;
    font-size: 1.2rem;
    z-index: 5;
    outline: none;
    cursor: pointer;
    transition: all .2s ease; }
    .App-toggleBar .App-toggleBarBtn.enabled {
      background-color: rgba(240, 248, 255, 0.8); }
      .App-toggleBar .App-toggleBarBtn.enabled:active, .App-toggleBar .App-toggleBarBtn.enabled:hover, .App-toggleBar .App-toggleBarBtn.enabled:focus {
        background-color: rgba(127, 179, 213, 0.2); }
    .App-toggleBar .App-toggleBarBtn.disabled {
      background-color: rgba(127, 179, 213, 0.8);
      color: rgba(240, 248, 255, 0.8); }
      .App-toggleBar .App-toggleBarBtn.disabled:active, .App-toggleBar .App-toggleBarBtn.disabled:hover, .App-toggleBar .App-toggleBarBtn.disabled:focus {
        background-color: rgba(127, 179, 213, 0.5); }

.App-noticeBanner {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3rem 1rem 1rem;
  background-color: rgba(127, 179, 213, 0.8);
  z-index: 18;
  transition: padding .2s ease; }
  .App-noticeBanner.enabled {
    display: flex; }
  .App-noticeBanner.disabled {
    display: none; }
  .App-noticeBanner.statusBar-open {
    padding-left: 230px; }
  .App-noticeBannerText {
    flex-grow: 1;
    margin: 0;
    font-size: 1.2rem;
    text-align: left;
    color: #f0f8ff;
    user-select: all; }
  .App-noticeBannerBtn {
    position: absolute;
    top: .5rem;
    right: .5rem;
    border: 0;
    border-radius: 5px;
    margin: .25rem;
    background-color: #f0f8ff;
    color: #2D2F32;
    font-weight: bold;
    font-size: 1.2rem;
    outline: none;
    cursor: pointer; }

.App-title {
  font-size: 2.4rem;
  margin: 1rem 0 0; }

.App-section {
  max-width: 634px;
  margin: 1rem auto;
  padding: 0 1rem; }
  .App-section + .App-section {
    border-top: 2px solid #f0f8ff;
    padding-top: 1rem; }

.App-section-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  position: relative;
  max-width: initial; }
  .App-section-main > div {
    padding: 0 .5rem; }
  .App-section-main .App-padContainer {
    z-index: 5; }
  .App-section-main .App-keysContainer {
    width: 270px;
    min-width: 120px;
    max-width: calc( 100vw - 90px);
    z-index: 5; }

.App-section-speakForm form {
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .App-section-speakForm form .App-btn {
    position: relative; }
    .App-section-speakForm form .App-btn:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: rgba(240, 248, 255, 0.8);
      border-width: 7px;
      margin-top: -7px;
      transition: all .2s ease; }
    .App-section-speakForm form .App-btn:active:after, .App-section-speakForm form .App-btn:focus:after {
      border-right-color: rgba(127, 179, 213, 0.8); }

.App-activeComponents > div {
  border-radius: 5px;
  border: 1px solid #74E08B; }

.App-activeComponents > div + div {
  margin-top: 1rem; }

.App-btn,
.App-textarea,
.App-input,
.App-key {
  padding: .5rem 1rem;
  background-color: rgba(240, 248, 255, 0.8);
  border: 0;
  border-radius: 5px;
  margin: .25rem;
  z-index: 5;
  transition: all .2s ease; }
  .App-btn:active, .App-btn:focus,
  .App-textarea:active,
  .App-textarea:focus,
  .App-input:active,
  .App-input:focus,
  .App-key:active,
  .App-key:focus {
    background-color: rgba(127, 179, 213, 0.8);
    color: #f0f8ff;
    outline: none; }

.App-btn {
  cursor: pointer; }
  .App-btn.dark {
    background-color: rgba(45, 47, 50, 0.8);
    color: #f0f8ff; }
    .App-btn.dark:active, .App-btn.dark:focus {
      background-color: rgba(127, 179, 213, 0.8);
      outline: none; }

.App-input,
.App-textarea {
  padding: .5rem; }
  .App-input:active::placeholder, .App-input:focus::placeholder,
  .App-textarea:active::placeholder,
  .App-textarea:focus::placeholder {
    color: rgba(240, 248, 255, 0.5); }
  .App-input:active::-moz-placeholder, .App-input:focus::-moz-placeholder,
  .App-textarea:active::-moz-placeholder,
  .App-textarea:focus::-moz-placeholder {
    color: rgba(240, 248, 255, 0.5); }

.App-textareaContainer {
  position: relative; }
  .App-textareaContainer::after {
    content: "◲";
    pointer-events: none;
    font-size: 18px;
    line-height: 1;
    position: absolute;
    height: 20px;
    width: 20px;
    text-align: center;
    bottom: 2px;
    right: 0;
    z-index: 6;
    background-color: #f0f8ff;
    color: rgba(127, 179, 213, 0.8);
    border-radius: 5px; }
  .App-textareaContainer .App-textarea {
    min-width: 100px;
    max-width: 200px;
    min-height: 30px;
    max-height: 200px;
    margin: 0;
    position: relative; }

.App-slider {
  width: 100%; }

.App-feedContainer {
  position: relative; }
  .App-feedContainer .App-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1; }
  .App-feedContainer .App-feed {
    font-size: 1.4rem;
    padding: 0;
    border: 1px solid #7FB3D5;
    border-radius: 5px;
    min-height: 50px;
    max-height: 200px;
    overflow: auto;
    user-select: text; }
    .App-feedContainer .App-feed li {
      font-size: 0.9rem;
      line-height: 1.5; }
    .App-feedContainer .App-feed:active, .App-feedContainer .App-feed:focus {
      background-color: rgba(127, 179, 213, 0.2);
      outline: none; }

.App .form-group {
  margin: 1rem 0; }
  .App .form-group > .App-key:first-child {
    margin-left: 0; }
  .App .form-group > .App-key:last-child {
    margin-right: 0; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
